import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  width: "100%",
  minHeight: "54px",
  borderRadius: "16px",
  fontWeight: "semibold",
});

const secondary = defineStyle({
  ...baseStyle,
  borderWidth: "1px",
  borderColor: "accent.red",
  color: "accent.red",
});

const external = defineStyle({
  ...baseStyle,
  justifyContent: "flex-start",
  fontSize: "14px",
  fontWeight: "400",
  color: "text.body",
  borderWidth: "1px",
  borderColor: "text.body",
});

const primary = defineStyle({
  ...baseStyle,
  background: "linear-gradient(85deg, #970E5F 0%, #DA2852 100%)",
  color: "white",

  _disabled: {
    _hover: {
      background: "linear-gradient(85deg, #970E5F 0%, #DA2852 100%) !important",
      color: "white !important",
    },
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: { secondary, external, primary },
  defaultProps: { variant: "primary" },
});
