import { chakra, List, ListItem, VStack } from "@chakra-ui/react";

const MainContainer = chakra(VStack, {
  baseStyle: {
    boxShadow: "card",
    background: "background.white",
    flexGrow: "1",
    borderRadius: "16px 16px 0 0",
    width: "100%",
    position: "relative",
    alignItems: "start",
    justifyContent: "space-between",
    padding: "16px 16px 10px",
    height: "100%",
    gap: "30px",
  },
});

const Container = chakra(VStack, {
  baseStyle: {
    width: "100%",
    height: "100%",
    flexGrow: "1",
    alignItems: "start",
    gap: "16px",
  },
});

const RequirementsList = chakra(List, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    counterReset: "myCounter",
  },
});

const RequirementsItem = chakra(ListItem, {
  baseStyle: {
    position: "relative",
    paddingLeft: "31px",
    fontSize: "12px",
    color: "text.body",
    counterIncrement: "myCounter",

    _before: {
      position: "absolute",
      content: "counter(myCounter)",
      left: "5px",
      top: "1px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "16px",
      height: "16px",
      fontSize: "8px",
      lineHeight: "10px",
      color: "accent.red",
      borderWidth: "1px",
      borderColor: "accent.red",
      borderRadius: "50%",
    },
  },
});

export { Container, MainContainer, RequirementsList, RequirementsItem };
