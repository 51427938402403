import { Button, Link, Spinner, useBoolean, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectOption } from "../../../../../types";
import { ControlledCheckbox } from "../../../../common/Checkbox/ControlledCheckbox";
import { ControlledInput } from "../../../../common/CommonInput/ControlledInput";
import { ControlledSelect } from "../../../../common/CommonSelect/ControlledSelect";
import { Stepper } from "../../../../common/Stepper/Stepper";
import { Header } from "../../../FormBuilder/FormBuilder.styles";
import { getAddress, getCities, getPlatforms } from "../../ReviewForm.api";
import { AGREE_LINK } from "../../ReviewForm.constants";
import { FirstFormSchema } from "./FirstFormStep.schema";

const CITY_KEY = "city";
const CITY_POINT_KEY = "cityPoint";

interface FirstFormStepProps {
  onNext: (data: any) => void;
  onChange: (data: any) => void;
  header: string;
  description: ReactNode;
  defaultValues: any;
}

export const FirstFormStep = ({
  defaultValues,
  onNext,
  header,
  description,
}: FirstFormStepProps) => {
  const [isAddressVisible, { off: setAddressInvisible, on: setAddressVisible }] = useBoolean();
  const [isValid, { off: setFalse, on: setTrue }] = useBoolean();

  const [platforms, setPlatforms] = useState<SelectOption<{ icon: string; name: string }>[]>([]);
  const [address, setAddress] = useState<SelectOption[]>([]);
  const [cities, setCities] = useState<string[]>([]);

  const form = useForm({
    resolver: zodResolver(FirstFormSchema),
    mode: "onSubmit",
  });

  const {
    getValues,
    watch,
    formState: { isSubmitting },
    reset,
  } = form;

  const variant = isValid ? "primary" : "secondary";

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  // Принудительная валидация формы.
  useEffect(() => {
    try {
      FirstFormSchema.parse(getValues());
      setTrue();
    } catch (e) {
      setFalse();
    }
  }, [watch()]);

  useEffect(() => {
    getCities().then(setCities);
  }, []);

  // При обновлении опции города стираем выбранное значение адреса.
  useEffect(() => {
    const { value } = form.getValues(CITY_KEY) || {};

    if (value) {
      getAddress(value).then(setAddress);
      setAddressVisible();
    } else {
      setAddressInvisible();
      form.setValue(CITY_POINT_KEY, null);
    }
  }, [form.watch(CITY_KEY)]);

  useEffect(() => {
    getPlatforms().then(setPlatforms);
  }, []);

  return (
    <FormProvider {...form}>
      <Stepper activeStepIndex={0} onBack={() => {}} steps={3} />
      <VStack gap="12px" w="full" alignItems="start">
        <Header noOfLines={3}>{header}</Header>
        {description}
      </VStack>
      <ControlledInput name="name" placeholder="Имя" label="Введите имя" isRequired />
      <ControlledSelect
        label="Укажите город"
        placeholder="Город"
        options={cities}
        name="city"
        isRequired
      />
      <ControlledSelect
        placeholder="Выберите филиал"
        visible={isAddressVisible}
        label="Выберите филиал"
        options={address}
        name="cityPoint"
        isSearchable
        isRequired
      />
      <ControlledSelect
        placeholder="Выберите площадку"
        label="Выберите площадку"
        options={platforms}
        isRequired
        name="app"
      />
      <ControlledCheckbox name="agree">
        <>
          Я согласен на{" "}
          <Link href={AGREE_LINK} textDecoration="none" color="accent.red">
            обработку персональных данных
          </Link>
        </>
      </ControlledCheckbox>
      <Button
        onClick={form.handleSubmit(onNext)}
        opacity={isSubmitting || !isValid ? 0.5 : 1}
        variant={variant}
        gap="10px"
      >
        Далее
        {isSubmitting ? <Spinner size={"sm"} color="white" /> : null}
      </Button>
    </FormProvider>
  );
};
