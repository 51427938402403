import { Button, Spinner, Text, VStack } from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";
import { ControlledSelect } from "../../../../common/CommonSelect/ControlledSelect";
import { ControlledPhoneInput } from "../../../../common/PhoneInput/ControlledPhoneInput";
import { Stepper } from "../../../../common/Stepper/Stepper";
import { Header } from "../../../FormBuilder/FormBuilder.styles";
import { replenishmentTypes } from "../../ReviewForm.constants";
import { useThirdFormStep } from "./ThirdFormStep.hooks";
import { ThirdFormStepProps } from "./ThirdFormStep.types";
import { WhatsAppButton } from "../../FormStates/SuccessState/WhatsAppButton/WhatsAppButton";

export const ThirdFormStep = ({ onNext, header, description, ...props }: ThirdFormStepProps) => {
  const { form, handleBack, isBanksVisible, banks, isSubmitting, isValid, variant, isLoading } =
    useThirdFormStep(props);

  return (
    <FormProvider {...form}>
      <Stepper activeStepIndex={2} onBack={handleBack} steps={3} />
      <VStack gap="12px" w="full" alignItems="start">
        <Header noOfLines={3}>{header}</Header>
        {description}
      </VStack>
      <ControlledSelect
        placeholder="Выберите способ пополнения"
        options={replenishmentTypes}
        label="Способ пополнения"
        name="refill"
      />
      <ControlledPhoneInput
        placeholder="+7 (___) ___-__-__"
        label="Введите номер телефона"
        name="phone"
      />
      <ControlledSelect
        placeholder="Выберите банк"
        visible={isBanksVisible}
        label="Выберите банк"
        options={banks}
        name="bank"
      />
      <VStack gap="10px" w="full">
        <Button
          opacity={isSubmitting || !isValid ? 0.5 : 1}
          disabled={isSubmitting || isLoading || !isValid}
          onClick={form.handleSubmit(onNext)}
          variant={variant}
          gap="10px"
        >
          Отправить
          {isSubmitting ? <Spinner size={"sm"} color="white" /> : null}
        </Button>
        <Button onClick={handleBack} variant="secondary">
          Назад
        </Button>
      </VStack>

      <VStack gap="10px" mt="8px" alignSelf="center">
        <Text color="text.body">Возникли проблемы?</Text>
        <WhatsAppButton />
      </VStack>
    </FormProvider>
  );
};
