import { extendTheme } from "@chakra-ui/react";
import { buttonTheme, checkboxTheme, inputTheme } from "./components/common";

export const theme = extendTheme({
  colors: {
    background: {
      gray: "#EDEEF0",
      white: "#FFFFFF",
      green: "#009846",
      red: "#D82853",
    },
    text: {
      body: "#595959",
      heading: "#1B1B1B",
      gray: "#B7B7B7",
      green: "#135F20",
    },
    accent: {
      red: "#D82853",
      green: "#E2FFE7",
    },
    image: {
      gray: "#C9C9C9",
    },
  },
  shadows: {
    card: "0px 1px 15px 0px rgba(0, 0, 0, 0.05), 1px -1px 15px 2px rgba(0, 0, 0, 0.05)",
  },
  components: {
    Input: inputTheme,
    Checkbox: checkboxTheme,
    Button: buttonTheme,
  },
});
