import { Button, Link, Spinner, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ControlledImagePicker } from "../../../../common/ImagePicker/ControlledImagePicker";
import { SecondFormSchema } from "./SecondFormStep.schema";
import { Stepper } from "../../../../common/Stepper/Stepper";
import { Header } from "../../../FormBuilder/FormBuilder.styles";
import { REVIEW_TEMPLATES_LINK } from "../../ReviewForm.constants";

interface SecondFormStepProps {
  onNext: (data: any) => void;
  onChange: (data: any) => void;
  header: string;
  description: ReactNode;
  onBack: () => void;
  defaultValues: any;
}

export const SecondFormStep = ({
  onNext,
  onBack,
  onChange,
  defaultValues,
  header,
  description,
}: SecondFormStepProps) => {
  const form = useForm({
    resolver: zodResolver(SecondFormSchema),
    mode: "onSubmit",
  });

  const {
    getValues,
    watch,
    formState: { isSubmitting },
  } = form;

  const [isValid, setValid] = useState(false);

  const variant = isValid ? "primary" : "secondary";

  const handleBack = () => {
    onChange(getValues());
    onBack();
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  // Принудительная валидация формы.
  useEffect(() => {
    try {
      SecondFormSchema.parse(getValues());
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  }, [watch()]);

  return (
    <FormProvider {...form}>
      <Stepper activeStepIndex={1} onBack={handleBack} steps={3} />
      <VStack gap="12px" w="full" alignItems="start">
        <Header noOfLines={3}>{header}</Header>
        {description}
      </VStack>
      <ControlledImagePicker name="screenshot" />
      <VStack gap="10px" w="full">
        <Link w="full" href={REVIEW_TEMPLATES_LINK} variant="outlined" isExternal>
          <Button variant="external" justifyContent="center">
            Пример отзыва
          </Button>
        </Link>
        <Button
          onClick={form.handleSubmit(onNext)}
          opacity={isSubmitting || !isValid ? 0.5 : 1}
          variant={variant}
          gap="10px"
        >
          Далее
          {isSubmitting ? <Spinner size={"sm"} color="white" /> : null}
        </Button>
        <Button onClick={handleBack} variant="secondary">
          Назад
        </Button>
      </VStack>
    </FormProvider>
  );
};
