import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { FormSchema } from "../FormBuilder/FormBuilder.types";
import { uploadForm } from "./ReviewForm.api";
import { STEPS_NUMBER } from "./ReviewForm.constants";
import { forms } from "./ReviewForm.data";
import { ReviewForm } from "./ReviewForm.types";
import { getUserGeoData } from "../../../screens/BlockedVPNScreen.api";

export const useReviewForm = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [formData, setFormData] = useState<object>();
  const [defaultValues, setDefaultValues] = useState<any>({});

  const [activeForm, setActiveForm] = useState<FormSchema>();
  const [formSubmitState, setFormSubmitState] = useState<boolean | undefined>(undefined);

  const handleBack = () => {
    setActiveIndex(activeIndex - 1);
  };

  const handleSubmit = async (fieldValues: FieldValues) => {
    if (activeIndex === STEPS_NUMBER - 1) {
      const isFormSuccess = await uploadForm({ ...formData, ...fieldValues } as ReviewForm);

      setFormSubmitState(isFormSuccess);
    } else {
      setFormData({ ...formData, ...fieldValues });
      onChange(fieldValues);

      setActiveIndex(activeIndex + 1);
    }
  };

  const onChange = (data: any) => {
    const filledFormSteps = { ...defaultValues, [activeIndex]: data };
    setDefaultValues(filledFormSteps);
  };

  const handleYes = () => {
    setFormSubmitState(undefined);
    setActiveIndex(0);
  };

  const handleNewReview = () => {
    setDefaultValues({});
    handleYes();
  };

  useEffect(() => {
    setActiveForm(forms[activeIndex]);
  }, [activeIndex]);

  // Получает и устанавливает ip-адрес пользователя.
  useEffect(() => {
    getUserGeoData().then((data) => {
      setFormData({ ...formData, userIp: data?.ip });
    });
  }, []);

  return {
    states: {
      formSubmitState,
      activeForm,
      activeStepIndex: activeIndex,
      defaultValues: defaultValues[activeIndex],
      formData,
    },
    actions: { handleBack, handleNewReview, handleSubmit, handleYes, onChange },
  };
};
