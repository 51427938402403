import { useEffect, useState } from "react";
import { PlatformList } from "./FormSuccess.types";
import { getPlatform } from "./FormSuccess.api";

export const usePlatformList = () => {
  const [platformList, setPlatformList] = useState<PlatformList[]>([]);

  useEffect(() => {
    getPlatform().then((res) => setPlatformList(res));
  }, []);

  return platformList;
};
