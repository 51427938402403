import { z } from "zod";

const MAX_FILE_SIZE = 4.8;
const MB_PREFIX = 1024 * 1024;

const REQUIRED_MESSAGE = "Важно! Все поля являются обязательными для заполнения";
const PHONE_INPUT_MESSAGE = "Неверно указан телефон, исправьте ошибку";
const INCORRECT_NAME_MESSAGE =
  "Имя пользователя должно содержать только символы кириллицы или латиницы";
const MAX_FILE_SIZE_MESSAGE = "Вы загрузили слишком большой файл";

const russianPattern = /^[\u0400-\u04FF ]+$/;
const englishPattern = /^[a-zA-Z ]+$/;

const CORRECT_NUMBER_LENGTH = 10;

const RequiredTextField = z
  .string()
  .min(1, REQUIRED_MESSAGE)
  .refine((name) => russianPattern.test(name) || englishPattern.test(name), INCORRECT_NAME_MESSAGE);

const PhoneInput = z
  .string()
  .min(1, REQUIRED_MESSAGE)
  .refine((value) => {
    const withoutSpecialSymbols = value.replace(/[()_ -]/g, "");

    return withoutSpecialSymbols.length === CORRECT_NUMBER_LENGTH;
  }, PHONE_INPUT_MESSAGE);

const SelectOption = z.object({
  label: z.string().min(1),
  value: z.string().min(1).or(z.number()),
});

const SelectOptionWithIcon = z.object({
  label: z.object({
    icon: z.string(),
    name: z.string(),
  }),
  value: z.string().min(1).or(z.number()),
});

const File = z
  .any()
  .refine((file) => Boolean(file), "Пожалуйста, загрузите файл")
  .refine((file) => file?.size / MB_PREFIX <= MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE);

export { File, PhoneInput, RequiredTextField, SelectOption, SelectOptionWithIcon };
