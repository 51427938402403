import { Text, VStack } from "@chakra-ui/react";
import { VPNIcon } from "../components/svg/VpnIcon";
import { NewAotzivLogo } from "../components/svg";

export const BlockedVPNScreen = () => {
  return (
    <VStack padding="285px 16px 46px" height="100vh" bgColor="background.white">
      <VStack
        mb="auto"
        padding="20px 10px"
        bgColor="background.red"
        textAlign="center"
        color="white"
        borderRadius="16px"
      >
        <VPNIcon />
        <Text fontSize={18} fontWeight="500">
          Возможно, у вас подключен VPN
        </Text>
        <Text fontSize={14}>Если есть проблемы с работой сайта, рекомендуем его отключить</Text>
      </VStack>
      <NewAotzivLogo />
    </VStack>
  );
};
