import { Button, HStack, Image, Link, Text } from "@chakra-ui/react";

import WhatsUpLogo from "../../../../../../assets/WhatsUpLogo.png";

import { CONTACT_US_LINK } from "../../../ReviewForm.constants";

export const WhatsAppButton = () => (
  <Link href={CONTACT_US_LINK} isExternal>
    <Button variant="external" borderRadius="100px" background="accent.green" border="none">
      <HStack gap="9px">
        <Image src={WhatsUpLogo} width="24px" height="24px" />
        <Text color="text.green">Связаться с нами</Text>
      </HStack>
    </Button>
  </Link>
);
