export const CrossIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none">
      <rect width="19" height="19" y="19" fill="#EDEEF0" rx="9.5" transform="rotate(-90 0 19)" />
      <path
        stroke="#595959"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m6.341 12.659 6.35-6.35m-6.35 0 6.35 6.35"
      />
    </svg>
  );
};
