export const VPNIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="66" fill="none">
      <path
        fill="#fff"
        d="M32.5 57.375c-3.389 0-6.567-.633-9.533-1.899-2.967-1.265-5.549-2.999-7.746-5.2-2.197-2.2-3.93-4.783-5.197-7.745-1.268-2.963-1.9-6.14-1.899-9.531 0-3.4.633-6.58 1.899-9.539 1.265-2.96 2.999-5.541 5.2-7.74 2.2-2.2 4.782-3.932 7.745-5.197 2.963-1.266 6.14-1.899 9.531-1.899 1.109 0 2.183.071 3.223.214a27.54 27.54 0 0 1 3.085.604c.538.135.976.421 1.313.858.338.437.506.93.504 1.48v2.26c0 1.49-.53 2.766-1.59 3.828-1.06 1.061-2.335 1.591-3.827 1.59h-5.416v5.416c0 .767-.26 1.411-.78 1.931s-1.163.78-1.929.777h-5.416V33h16.25c.767 0 1.41.26 1.93.78s.78 1.163.778 1.928v8.125h2.708c1.22 0 2.292.361 3.218 1.084.926.722 1.569 1.625 1.928 2.708 1.76-1.916 3.15-4.121 4.166-6.617 1.016-2.495 1.523-5.164 1.522-8.008v-.677a3.63 3.63 0 0 0-.055-.623c0-.377.125-.706.374-.986.25-.28.572-.42.967-.422.386 0 .703.117.948.352.246.234.386.546.422.934.035.244.052.479.052.704v.704c0 3.399-.633 6.58-1.898 9.545-1.266 2.964-3 5.546-5.2 7.745-2.202 2.2-4.78 3.933-7.738 5.2-2.958 1.268-6.137 1.9-9.539 1.899Zm-2.708-2.844V49.25c-1.49 0-2.765-.53-3.825-1.59s-1.59-2.335-1.592-3.827v-2.708l-13-13c-.135.813-.26 1.625-.371 2.438a18.124 18.124 0 0 0-.17 2.437c0 5.529 1.817 10.33 5.451 14.403 3.635 4.073 8.137 6.45 13.507 7.128ZM48.23 24.875c-.63 0-1.152-.208-1.565-.623-.414-.415-.621-.937-.623-1.565v-7.914c0-.628.207-1.15.623-1.566.415-.415.937-.622 1.565-.622h.52V9.876c0-1.349.46-2.484 1.381-3.407.923-.92 2.06-1.381 3.413-1.381 1.352 0 2.488.46 3.407 1.381.92.921 1.381 2.058 1.381 3.41v2.708h.523c.628 0 1.15.208 1.565.623.416.415.623.936.623 1.563v7.916c0 .63-.207 1.152-.623 1.566-.415.415-.937.623-1.565.623l-10.625-.003Zm2.603-12.29h5.416V9.876c0-.767-.259-1.41-.777-1.93s-1.162-.78-1.931-.778c-.77.002-1.412.26-1.928.777-.517.517-.777 1.16-.78 1.931v2.709Z"
      />
    </svg>
  );
};
