import { useRef } from "react";
import { InputWrapper } from "../../wrappers";
import { EmptyPicker } from "./EmptyPicker/EmptyPicker";
import { ImagePickerProps } from "./ImagePicker.types";
import { PickedImage } from "./PickedImage/PickedImage";

export const ImagePicker = ({ value, onChange, errorMessage }: ImagePickerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleDelete = () => onChange(null);

  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const currentFile = files?.[0];

    if (currentFile) {
      onChange(currentFile);
    }
  };

  return (
    <InputWrapper isRequired={false} errorMessage={errorMessage}>
      {value ? (
        <PickedImage src={value} onClick={handleDelete} hasError={Boolean(errorMessage)} />
      ) : (
        <EmptyPicker onClick={handleClick} />
      )}
      <input
        ref={inputRef}
        onClick={handleClear}
        onChange={handleChange}
        className="hidden"
        accept="image/*"
        type="file"
      />
    </InputWrapper>
  );
};
