import { VStack } from "@chakra-ui/react";
import { Header } from "../components/common";
import { ReviewForm } from "../components/forms";
import { KamkomBankLogo } from "../components/svg";
import { BlockedVPNScreen } from "./BlockedVPNScreen";
import { useEffect, useState } from "react";
import { getUserGeoData } from "./BlockedVPNScreen.api";

// const FormSwitcher = ({
//   activeIndex,
//   onNext,
//   onBack,
//   defaultValues,
// }: {
//   activeIndex: number;
//   onNext: (data: any) => void;
//   onBack: () => void;
//   defaultValues: any;
// }) => {
//   switch (activeIndex) {
//     case 0: {
//       return <First onNext={onNext} defaultValues={defaultValues} />;
//     }
//     case 1: {
//       return <Second onNext={onNext} onBack={onBack} />;
//     }
//     default: {
//       return <Third onNext={onNext} onBack={onBack} />;
//     }
//   }
// };

const DEFAULT_USER_LOCATION = "Russia";

export const FormScreen = () => {
  const [userLocation, setUserLocation] = useState(DEFAULT_USER_LOCATION);

  useEffect(() => {
    getUserGeoData().then((data) => data && setUserLocation(data?.country_name));
  }, []);

  const isDefaultLocation = userLocation === DEFAULT_USER_LOCATION;

  // const [activeIndex, setActiveIndex] = useState(0);
  // const [formData, setFormData] = useState<any>({});

  // const onNext = (data: any) => {
  //   setFormData({ ...formData, [activeIndex]: data });
  //   setActiveIndex(activeIndex + 1);
  // };

  // const onBack = () => setActiveIndex(activeIndex - 1);

  if (!isDefaultLocation) {
    return <BlockedVPNScreen />;
  }

  return (
    <VStack padding="16px 16px 0" gap="16px" bg="background.gray" minH="100vh">
      <Header logo={<KamkomBankLogo />} phone="+7(800) 200-86-38" />
      <ReviewForm />
    </VStack>
  );
};
