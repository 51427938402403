import { Button, chakra, Text } from "@chakra-ui/react";

const HTMLText = chakra(Text, {
  baseStyle: {
    position: "relative",
    color: "text.body",
    counterIncrement: "myCounter",

    ol: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      counterReset: "myCounter",
    },

    li: {
      position: "relative",
      paddingLeft: "31px",
      color: "text.body",
      counterIncrement: "myCounter",

      a: {
        color: "accent.red",
      },

      _before: {
        position: "absolute",
        content: "counter(myCounter)",
        left: "5px",
        top: "1px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  
        width: "16px",
        height: "16px",
        fontSize: "8px",
        lineHeight: "10px",
        color: (theme) => theme.colors.accent.red,
        border: (theme) => `1px solid ${theme.colors.accent.red}`,
        borderRadius: "50%",
      },
    },
  },
});

export { HTMLText };
