import { Controller } from "react-hook-form";
import { ConnectForm } from "../../providers";
import { ControlledInputProps } from "../CommonInput/CommonInput.types";
import { PhoneInput } from "./PhoneInput";

export const ControlledPhoneInput = ({ name, ...props }: ControlledInputProps) => (
  <ConnectForm>
    {({ control }) => (
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <PhoneInput
            errorMessage={errors[name]?.message?.toString()}
            onChange={(event) => onChange(event.target.value)}
            value={value}
            {...props}
          />
        )}
      />
    )}
  </ConnectForm>
);
