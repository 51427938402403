interface ChevronIconProps {
  width?: string;
  height?: string;
  stroke?: string;
}

export const ChevronIcon = ({
  width = "26",
  height = "26",
  stroke = "#B7B7B7",
}: ChevronIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.6875 9.34375L13 16.6562L20.3125 9.34375"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
