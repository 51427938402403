import axios, { AxiosError } from "axios";
import { UserGeoData } from "./BlockedVPNScreen.types";

export const getUserGeoData = async () => {
  try {
    const res = await axios.get<UserGeoData>(
      `https://api.ipgeolocation.io/ipgeo?apiKey=6c9b0ffcc4fc46a5a01d5ba72e659987`
    );
    return res.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        console.error(error.response.data.message);
      }
      if (error.request) {
        console.error("Ошибка при выполнении запроса", error.message);
      }
    } else {
      console.error("Произошла непредвиденная ошибка, перезагрузите страницу");
    }
  }
};
