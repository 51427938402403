import { Box, Mark, Text } from "@chakra-ui/react";
import { FormSchema } from "../FormBuilder/FormBuilder.types";
import {
  firstFormElements,
  FirstFormSchema,
  secondFormElements,
  SecondFormSchema,
  thirdFormElements,
  ThirdFormSchema,
} from "./FormSteps";
import { requirementsToScreenshot } from "./ReviewForm.constants";
import { RequirementsItem, RequirementsList } from "./ReviewForm.styles";


export const forms: FormSchema[] = [
  {
    header: "Заполните все данные в анкете и отправьте свой отзыв на проверку",
    elements: firstFormElements,
    schema: FirstFormSchema,
    position: "static",
  },
  {
    header: "Добавьте скриншот отзыва",
    description: (
      <Box>
          <Text fontSize="14px" color="text.heading" mb="10px">
            <Mark color="accent.red">Внимание!</Mark> На скриншоте должны быть видны следующие
            данные:
          </Text>
          <RequirementsList>
            {requirementsToScreenshot.map((requirement) => (
              <RequirementsItem key={requirement} fontSize="12px">
                {requirement}
              </RequirementsItem>
            ))}
          </RequirementsList>
        </Box>
    ),
    elements: secondFormElements,
    schema: SecondFormSchema,
  },
  {
    header: "Выберите удобный для вас способ получения вознаграждения",
    elements: thirdFormElements,
    schema: ThirdFormSchema as any,
  },
];
