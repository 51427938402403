import { api } from "../../../api";
import { SelectOption } from "../../../types";
import { transformPhoneNumber } from "../../../utils";
import { cities, replenishmentTypes } from "./ReviewForm.constants";
import { FieldData, ReviewForm } from "./ReviewForm.types";

const getCities = async () =>
  api
    .get("/address")
    .then((response) => response.data)
    .then((data) => data.map((item: any) => ({ label: item.name, value: item.name })));

const getAddress = async (cityName: string | undefined) => {
  const places = await api
    .get("/address", { params: { name: cityName } })
    .then((response) => response.data);

  const currentPlace = places.find((place: any) => place.name === cityName);

  const addresses = currentPlace?.addresses.map((address: any) => ({
    label: address.address_name,
    value: address.id,
  }));

  return addresses;
};

const getPlatforms = async (): Promise<SelectOption<{ icon: string; name: string }>[]> => {
  return api
    .get<SelectOption[]>("/platforms")
    .then((response) => response.data)
    .then((data: any[]) =>
      data.map(({ title, id, logo_image }) => ({
        label: {
          icon: logo_image,
          name: title,
        },
        value: id,
      }))
    );
};

const getBanks = async (): Promise<SelectOption[]> => {
  return api
    .get<SelectOption[]>("/banks")
    .then((response) => response.data)
    .then((data: any[]) => data.map(({ title, id }) => ({ label: title, value: id })));
};

const uploadForm = async ({
  cityPoint,
  app,
  bank,
  name,
  screenshot,
  phone,
  refill,
  userIp,
}: ReviewForm) =>
  api
    .postForm("/send-lead", {
      user_ip: userIp,
      phone: transformPhoneNumber(phone),
      address_id: cityPoint.value,
      source_id: app.value,
      bank_id: refill.value === "СБП" ? bank?.value : undefined,
      name,
      image: screenshot,
    })
    .then(() => true)
    .catch(() => false);

const getFirstStepData = async (): Promise<FieldData[]> => {
  const platformList = await getPlatforms();

  return [
    { options: cities, name: "city" },
    { options: platformList as any, name: "app" },
  ];
};

interface CanPaymentParams {
  source_id: string;
  phone: string;
  user_ip?: string;
}

const getCanPayment = async (params: CanPaymentParams): Promise<boolean> => {
  return api
    .get<boolean>("/can-payment", { params })
    .then((response) => response.data)
    .catch(() => false);
};

const getThirdStepData = async (): Promise<FieldData[]> => {
  const banks = await getBanks();

  return [
    { name: "bank", options: banks },
    { name: "refill", options: replenishmentTypes },
  ];
};

export {
  getAddress,
  getBanks,
  getCanPayment,
  getCities,
  getFirstStepData,
  getPlatforms,
  getThirdStepData,
  uploadForm,
};
